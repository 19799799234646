import * as d3 from "d3";
import axios from "axios";

export default function loadPortfolios() {
  /* return Promise.all([d3.csv(data)]).then(datasets => {
    //console.log("loadData", datasets[0]);
  });*/
  return axios.get(
    `https://f77hsoddm4.execute-api.us-east-1.amazonaws.com/default/ii_rest/`
  );
  //return d3.csv(process.env.PUBLIC_URL + "/data/data_sample.csv");
}

export function loadFXTB() {
  return d3.csv(process.env.PUBLIC_URL + "/data/FXTB.csv");
}
export function loadFXDE() {
  return d3.csv(process.env.PUBLIC_URL + "/data/FXDE.csv");
}
export function loadFXRL() {
  return d3.csv(process.env.PUBLIC_URL + "/data/FXRL.csv");
}
export function loadFXRU() {
  return d3.csv(process.env.PUBLIC_URL + "/data/FXRU.csv");
}
export function loadFXUS() {
  return d3.csv(process.env.PUBLIC_URL + "/data/FXUS.csv");
}

export function loadPriorities() {
  return axios.get(
    "https://f77hsoddm4.execute-api.us-east-1.amazonaws.com/default/ii_rest/etfs"
  );
}

export function loadAssets(prio) {
  let promises = [];

  prio.sort((a, b) => (a.priority > b.priority ? 1 : -1));

  //console.log ('loadAssets', prio)
  prio.forEach((asset) =>
    promises.push(
      axios.get(
        `https://f77hsoddm4.execute-api.us-east-1.amazonaws.com/default/ii_rest/etfs?ticker=${asset.ticker}`
      )
    )
  );
  return Promise.all(promises);
}

export const data_tmp = {
  criteria: {
    terms: ["Neg-", "Neg", "Neut", "Pos", "Pos+"],
    outlooks: ["0.5", "1", "3", "5", "10"],
  },
  etfs: {
    tickers: ["FXTB", "FXUS", "FXRU", "FXRL", "FXDE"],
  },
  portfolios: {
    "0.5": {
      "Neg-": [0.35, 0.16, 0.15, 0.23, 0.11],
      Neg: [0.3, 0.19, 0.12, 0.25, 0.14],
      Neut: [0.24, 0.23, 0.09, 0.28, 0.16],
      Pos: [0.2, 0.25, 0.08, 0.29, 0.18],
      "Pos+": [0.18, 0.26, 0.07, 0.3, 0.19],
    },
    "1": {
      "Neg-": [0.35, 0.17, 0.14, 0.23, 0.11],
      Neg: [0.28, 0.2, 0.12, 0.26, 0.14],
      Neut: [0.22, 0.24, 0.09, 0.28, 0.17],
      Pos: [0.18, 0.26, 0.07, 0.3, 0.19],
      "Pos+": [0.16, 0.27, 0.06, 0.31, 0.2],
    },
    "3": {
      "Neg-": [0.33, 0.17, 0.14, 0.24, 0.12],
      Neg: [0.26, 0.21, 0.11, 0.27, 0.15],
      Neut: [0.19, 0.26, 0.06, 0.3, 0.19],
      Pos: [0.14, 0.29, 0.04, 0.32, 0.21],
      "Pos+": [0.1, 0.31, 0.03, 0.33, 0.23],
    },
    "5": {
      "Neg-": [0.35, 0.16, 0.15, 0.23, 0.11],
      Neg: [0.23, 0.23, 0.09, 0.28, 0.17],
      Neut: [0.15, 0.28, 0.05, 0.32, 0.2],
      Pos: [0.11, 0.3, 0.04, 0.33, 0.22],
      "Pos+": [0.08, 0.32, 0.02, 0.34, 0.24],
    },
    "10": {
      "Neg-": [0.37, 0.15, 0.16, 0.22, 0.1],
      Neg: [0.16, 0.27, 0.06, 0.31, 0.2],
      Neut: [0.09, 0.31, 0.03, 0.34, 0.23],
      Pos: [0.07, 0.33, 0.01, 0.35, 0.24],
      "Pos+": [0.05, 0.34, 0, 0.36, 0.25],
    },
  },
  analytics: {
    "0.5": {
      "Neg-": {
        eq_ratio: 0.498,
        bond_ratio: 0.502,
        return: 0.055,
        return_period: "3 мес.",
      },
      Neg: {
        eq_ratio: 0.583,
        bond_ratio: 0.417,
        return: 0.063,
        return_period: "3 мес.",
      },
      Neut: {
        eq_ratio: 0.665,
        bond_ratio: 0.335,
        return: 0.071,
        return_period: "3 мес.",
      },
      Pos: {
        eq_ratio: 0.715,
        bond_ratio: 0.285,
        return: 0.076,
        return_period: "3 мес.",
      },
      "Pos+": {
        eq_ratio: 0.739,
        bond_ratio: 0.261,
        return: 0.078,
        return_period: "3 мес.",
      },
    },
    "1": {
      "Neg-": {
        eq_ratio: 0.517,
        bond_ratio: 0.483,
        return: 0.057,
        return_period: "3 мес.",
      },
      Neg: {
        eq_ratio: 0.597,
        bond_ratio: 0.403,
        return: 0.064,
        return_period: "3 мес.",
      },
      Neut: {
        eq_ratio: 0.692,
        bond_ratio: 0.308,
        return: 0.073,
        return_period: "3 мес.",
      },
      Pos: {
        eq_ratio: 0.745,
        bond_ratio: 0.255,
        return: 0.078,
        return_period: "3 мес.",
      },
      "Pos+": {
        eq_ratio: 0.778,
        bond_ratio: 0.222,
        return: 0.082,
        return_period: "3 мес.",
      },
    },
    "3": {
      "Neg-": {
        eq_ratio: 0.528,
        bond_ratio: 0.472,
        return: 0.058,
        return_period: "3 мес.",
      },
      Neg: {
        eq_ratio: 0.631,
        bond_ratio: 0.369,
        return: 0.068,
        return_period: "3 мес.",
      },
      Neut: {
        eq_ratio: 0.758,
        bond_ratio: 0.242,
        return: 0.08,
        return_period: "3 мес.",
      },
      Pos: {
        eq_ratio: 0.815,
        bond_ratio: 0.185,
        return: 0.085,
        return_period: "3 мес.",
      },
      "Pos+": {
        eq_ratio: 0.866,
        bond_ratio: 0.134,
        return: 0.09,
        return_period: "3 мес.",
      },
    },
    "5": {
      "Neg-": {
        eq_ratio: 0.509,
        bond_ratio: 0.491,
        return: 0.056,
        return_period: "3 мес.",
      },
      Neg: {
        eq_ratio: 0.683,
        bond_ratio: 0.317,
        return: 0.073,
        return_period: "3 мес.",
      },
      Neut: {
        eq_ratio: 0.801,
        bond_ratio: 0.199,
        return: 0.084,
        return_period: "3 мес.",
      },
      Pos: {
        eq_ratio: 0.837,
        bond_ratio: 0.163,
        return: 0.087,
        return_period: "3 мес.",
      },
      "Pos+": {
        eq_ratio: 0.899,
        bond_ratio: 0.101,
        return: 0.093,
        return_period: "3 мес.",
      },
    },
    "10": {
      "Neg-": {
        eq_ratio: 0.473,
        bond_ratio: 0.527,
        return: 0.053,
        return_period: "3 мес.",
      },
      Neg: {
        eq_ratio: 0.774,
        bond_ratio: 0.226,
        return: 0.081,
        return_period: "3 мес.",
      },
      Neut: {
        eq_ratio: 0.873,
        bond_ratio: 0.127,
        return: 0.091,
        return_period: "3 мес.",
      },
      Pos: {
        eq_ratio: 0.915,
        bond_ratio: 0.085,
        return: 0.095,
        return_period: "3 мес.",
      },
      "Pos+": {
        eq_ratio: 0.952,
        bond_ratio: 0.048,
        return: 0.098,
        return_period: "3 мес.",
      },
    },
  },
};
