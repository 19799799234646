import React, { Component } from "react";
import ReactDOM from "react-dom";
import AutoScale from "react-auto-scale";
import { PieChart, Pie, Cell } from "recharts";
import { COLORS } from "../shared/config";

const PieNode = document.getElementById("pie_chart");
const PieNode_Tab = document.getElementById("pie_chart_tab");

let data = [
  { name: "FXTB", amount: 3 },
  { name: "FXUS", amount: 0.3 },
  { name: "FXRU", amount: 0.2 },
  { name: "FXRL", amount: 0.17 },
  { name: "FXDE", amount: 0.31 },
];

export default class PiePortfolio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.updateWindowDirection = this.updateWindowDirection.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    window.addEventListener("orientationchange", this.updateWindowDirection);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
    window.removeEventListener("orientationchange", this.updateWindowDirection);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    //console.log(
    //   "updateWindowDimensions wiw wih ",
    //   window.innerWidth,
    //   window.innerHeight
    // );
  }
  updateWindowDirection() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });

    //console.log(
    //   "updateWindowDirection wiw wih ",
    //   window.innerWidth,
    //   window.innerHeight
    // );
    window.location.reload(false); //radical variant
    /// this.props.reloadComponent();
  }

  render() {
    data = this.props.dataRow.map((d) => {
      return {
        amount: Math.round(d.amount * 100),
        name: d.name,
      };
    });

    return ReactDOM.createPortal(
      <AutoScale className="scale bar-scale">
        <PieChart
          className="piechart"
          width={this.state.width > 951 ? 240 : 320}
          height={240}
        >
          <text
            x={125}
            y={124}
            dy={0}
            fontSize="34"
            fill={"#46425c"}
            textAnchor="middle"
          >
            {this.props.history < 0 ? "" : "+"}
            {Math.round(this.props.history * 1000) / 10}%
          </text>

          <line
            x1={76}
            y1={129}
            x2={161}
            y2={129}
            stroke={"#00378b"}
            strokeWidth={1}
          ></line>
          <text
            x={118}
            y={150}
            dy={0}
            fontSize="16"
            fill={"#b4b6b8"}
            textAnchor="middle"
          >
            {"3 мес."}
          </text>
          <Pie
            data={data}
            cx={116}
            cy={116}
            paddingAngle={0}
            blendStroke={true}
            label={false}
            outerRadius={110}
            innerRadius={63}
            fill="#8884d8"
            dataKey="amount"
          >
            {this.props.dataRow.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </AutoScale>,
      this.state.width > 991 ? PieNode : PieNode_Tab
    );
  }
}
